import * as React from 'react';
import './ProgressBar.css';
import CN from 'classnames';
import {FC} from 'react';

interface IProgressBarProps {
    image: any;
}

const ProgressBar: FC<IProgressBarProps> = ({
                                              image
                                            }) => {

  return (
    <div className={CN('progressbar-container')}>
      {image}
    </div>
  );
}

export default ProgressBar;

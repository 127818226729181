import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import CN from 'classnames';
import './ScenerySelectionPage.css';
import { Context } from '../../Context';
import Header from '../../components/Header/Header';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import FaqButton from '../../components/FaqButton/FaqButton';
import Button from '../../components/Button.Library/Button.Link/ButtonLink';
import { ROUTES } from '../../app.router/ROUTES';
import { useNavigate } from 'react-router-dom';
import ButtonAction from '../../components/Button.Library/Button.Action/ButtonAction';
import toast from 'react-hot-toast';

const ScenerySelectionPage = () => {
  const navigate = useNavigate();

  const {
    pageTransition,
    pageVariants,
    scenesList,
    sceneSelected,
    setSceneSelected,
    activeScene,
    setActiveScene,
    deviceSelected,
    setDeviceSelected,
    setPreviousPage
  }: any = useContext(Context);

  const randomScene = () => {
    const randomIndex = Math.floor(Math.random() * scenesList.length);
    setActiveScene(scenesList[randomIndex]);
    navigate(`${ROUTES.MEDITATION_AUDIO}?&scene=${scenesList[randomIndex]?.title}`);
  };

  useEffect(() => {
    if (window.location.search.length) {
      const query = new URLSearchParams(window.location.search);
      const sceneParam = query.get('scene');
      if (sceneParam && sceneParam.toUpperCase() !== activeScene?.title.toUpperCase()) {
        const sceneObj = scenesList.find((el: any) => el.title.toUpperCase() === sceneParam.toUpperCase());
        if (sceneObj) {
          setActiveScene(sceneObj);
        } else {
          setActiveScene(scenesList[0]);
        }
      }

      const deviceParam = query.get('device');
      if (deviceParam && deviceParam.toUpperCase() !== deviceSelected.toUpperCase()) {
        setDeviceSelected(deviceParam);
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    setPreviousPage(`${ROUTES.SCENERY}`);
  }, []);

  return (
    <>
      <motion.div
        className="scenery-page"
        initial="in"
        animate="out"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Header linkAddress={`${ROUTES.DEVICES}`} linkText="Back" logoColor="blue"/>
        <div className="scenery-page-container">
          <ProgressBar
            image={<img src={'/assets/progress-bars/progress_line_step_3.svg'} alt="progress bar"
                        className="progressbar-image"/>}
          />
          <h2 className="scenery-page-title">
            Select scenery
          </h2>
          <div className="scenery-scenes-container">

            {scenesList
              .sort((a: any, b: any) => a.order - b.order)
              .map((scene: any, index: number) => (
                <div
                  className={CN(
                    'scenery-scene-image',
                    { 'scenery-scene-image-selected': activeScene?.title === `${scene.title}` },
                    { 'scenery-scene-image-forest': `${scene.title}` === 'Forest' },
                    { 'scenery-scene-image-beach': `${scene.title}` === 'Beach' },
                    { 'scenery-scene-image-lake': `${scene.title}` === 'Lake' },
                    { 'scenery-scene-image-unselected': sceneSelected && sceneSelected !== `${scene.title}` },
                  )}
                  onClick={() => {
                    if (scene.available) {
                      setSceneSelected(scene.title);
                      setActiveScene(scene);
                    } else {
                      toast.error('This scene will be up soon!');
                    }
                  }}
                  key={scene.title}
                >
                  <h3 className="scenery-scene-image-title">
                    {scene.title}
                  </h3>
                </div>
              ))}

          </div>
          <div className="scenery-buttons-container">
            <ButtonAction
              text="Surprise me"
              outlined
              onClickHandler={() => randomScene()}
            />
            <Button
              text="Next"
              filled
              linkAddress={`${ROUTES.MEDITATION_AUDIO}?&device=${deviceSelected}&scene=${activeScene?.title}`}
              disabled={!activeScene}
            />
          </div>
        </div>
      </motion.div>
      <FaqButton color="grey"/>
    </>
  );
}

export default ScenerySelectionPage;

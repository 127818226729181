import * as React from 'react';
import { motion } from 'framer-motion';
import CN from 'classnames';
import './FinalPage.css';
import Header from '../../components/Header/Header';
import FaqButton from '../../components/FaqButton/FaqButton';
import Button from '../../components/Button.Library/Button.Link/ButtonLink';
import { ROUTES } from '../../app.router/ROUTES';
import {useContext, useEffect} from 'react';
import {Context} from '../../Context';

const FinalPage = () => {
  const { setPreviousPage }: any = useContext(Context);

  const pageVariants = {
    in: {
      opacity: 0.5,
      x: '+100vw',
    },
    out: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0.5,
      x: '-100vw',
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.5,
  };

  useEffect(() => {
    setPreviousPage(`${ROUTES.FINAL}`);
  }, []);

  return (
    <>
      <motion.div
        className="final-page"
        initial="in"
        animate="out"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Header linkAddress="/" linkText="Return to start" logoColor="blue" />
        <div className="final-page-container">
          <h3 className="final-page-subtitle-0">OCHSNER EMPLOYEES</h3>
          <div className="final-page-title-wrapper">
            <h2 className="final-page-title">
              Want more ways to unwind?
            </h2>
          </div>
          <div className="final-page-image-container">
            <img
              src={'/assets/misc/cabana_pod_qr.svg'}
              alt="QR-code"
              className={CN('final-page-image')}
            />
          </div>
          <h3 className="final-page-subtitle-1">
            Create a Cabana account by QR Code
          </h3>
          <h3 className="final-page-subtitle-2">
            Open camera on your phone and click to link
          </h3>
          <div className="final-page-buttons-container">
            <Button text="Back to start" filled linkAddress={`${ROUTES.HOME}`} />
          </div>
        </div>
      </motion.div>
      <FaqButton color="grey" />
    </>
  );
}

export default FinalPage;

import React, {useContext, useEffect} from 'react';
import { motion } from 'framer-motion';
import DisclaimerModal from '../../components/DisclaimerModal/DisclaimerModal';
import Header from '../../components/Header/Header';
import './DisclaimerPage.css';
import { Context } from '../../Context';
import Button from '../../components/Button.Library/Button.Link/ButtonLink';
import FeelingMeter from '../../components/FeelingMeter/FeelingMeter';
import FaqButton from '../../components/FaqButton/FaqButton';
import { ROUTES } from '../../app.router/ROUTES'
import {useNavigate} from 'react-router-dom';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import {Simulate} from 'react-dom/test-utils';
import load = Simulate.load;

const DisclaimerPage = () => {
  const navigate = useNavigate();

  const {
    sceneSelected,
    audioSelected,
    disclaimerOpened,
    feelingValue,
    setFeelingValue,
    pageVariants,
    pageTransition,
    setPreviousPage
  }: any = useContext(Context);

  useEffect(() => {
    let selectedScene = '';
    let selectedMeditation = '';

    if (sceneSelected) {
      selectedScene = `&scene=${sceneSelected}`;
    }
    if (audioSelected) {
      selectedMeditation = `&meditation=${audioSelected}`;
    }

    navigate({
      pathname: '/disclaimer',
      search: `?${selectedScene}${selectedMeditation}`,
    });
  }, []);

  useEffect(() => {
    setPreviousPage(`${ROUTES.DISCLAIMER}`);
  }, []);


  return (
    <>
      <motion.div
        className="disclaimer-page"
        initial="in"
        animate="out"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <DisclaimerModal />
        <Header linkAddress={`${ROUTES.HOME}`} linkText="Return to start" logoColor="blue" />
        <div className="disclaimer-page-progressbar-container">
          <ProgressBar
            image={<img src={'/assets/progress-bars/progress_line_step_1.svg'} alt="progress bar" className="progressbar-image" />}
          />
        </div>
        <h2 className="disclaimer-page-title">
          Mood check-in
        </h2>
        <FeelingMeter
          feelingValue={feelingValue}
          setFeelingValue={setFeelingValue}
        />
        <div className="disclaimer-page-buttons-container">
          <Button
            text="Next"
            filled
            linkAddress={`${ROUTES.DEVICES}`}
          />
        </div>
      </motion.div>
      {!disclaimerOpened && <FaqButton color="grey"/>}
    </>
  );
}

export default DisclaimerPage;

import React, {useContext, useEffect} from 'react';
import { motion } from 'framer-motion';
import './PostVrPage.css';
import { ROUTES } from '../../app.router/ROUTES'
import Button from '../../components/Button.Library/Button.Link/ButtonLink';
import { Context } from '../../Context';
import FaqButton from '../../components/FaqButton/FaqButton';

const PostVrPage = () => {
  const { pageVariants, pageTransition, setDisclaimerOpened, setPreviousPage }: any = useContext(Context);

  useEffect(() => {
    setPreviousPage('/');
  }, []);

  return (
    <motion.div
      className="post-vr-page"
      initial="in"
      animate="out"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="post-vr-page-box">
        <img src={'/assets/logos/logo-white.svg'} alt="logo" className="post-vr-page-logo" />
        <h1 className="post-vr-page-title">
          All Done?
        </h1>
        <h3 className="post-vr-page-subtitle">
          Please return the VR headset<br />
          when finished
        </h3>
        <Button text="Continue" filled linkAddress={`${ROUTES.ALL_DONE}`} onClickHandler={() => setDisclaimerOpened(true)}/>
      </div>
      <FaqButton color="black"/>
    </motion.div>
  );
}

export default PostVrPage;

import React, {FC, useMemo} from 'react';
import {quotes} from '../../constants/app.config';
import {AnimatePresence, motion} from 'framer-motion';
import './Quote.css';

interface IQuote {
  device: string
}
const Quote: FC<IQuote> = ({device}) => {
  const randomQuote = useMemo(() => {
    return quotes[Math.floor(Math.random() * quotes.length)];
  }, []);

  const pageVariants = {
    in: {
      opacity: 0.5,
      x: '+100vw',
    },
    out: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0.5,
      x: '-100vw',
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.5,
  };

  return (
    <AnimatePresence>
      <motion.div
          className="meditation-page-quote-container"
          initial="in"
          animate="out"
          exit="exit"
          variants={pageVariants}
          transition={pageTransition}
        >
          <div className="meditation-page-quote">
            <img
              src="/assets/logos/logo-primary.svg"
              alt="Cabana logo"
              className="meditation-page-quote-logo"
            />
            <h3 className="meditation-page-quote-text">{randomQuote.quote}</h3>
            <h4 className="meditation-page-quote-author">- {randomQuote.author}</h4>
            {device === 'vr' && <h4 className="meditation-page-quote-subtext">Please remove your VR headset</h4>}
          </div>
        </motion.div>
    </AnimatePresence>
  );
}

export default Quote;

import './ViewerPage.css';
import CN from 'classnames';
import * as THREE from 'three';
import {motion} from 'framer-motion';
import { Context } from '../../Context';
import {Html, useProgress} from '@react-three/drei';
import { Leva } from 'leva'
import { Canvas } from '@react-three/fiber';
import { ROUTES } from '../../app.router/ROUTES';
import Header from '../../components/Header/Header';
import {audios} from '../../constants/app.config';
import Countdown from '../../components/Countdown/Countdown';
import React, {useContext, useEffect, useState} from 'react';
import { Scene } from '../../components/WebGL.components/Scene';
import {useNavigate} from 'react-router-dom';
import SoundControls from '../../components/SoundControls/SoundControls';
import ButtonAction from '../../components/Button.Library/Button.Action/ButtonAction';
import {Tablet} from '../../components/WebGL.components/Tablet';
import ReactGA from 'react-ga';

const ViewerPage = () => {
  const [isReady, setIsReady] = useState(false);
  const [countDownIsInView, setCountDownIsInView] = useState(true);
  const [sceneLoaderProgress, setSceneLoaderProgress] = useState(0);
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  const navigate = useNavigate();
  const startTime = Date.now();

  const {
    scenesList,
    setActiveScene,
    sceneSelected,
    audioSelected,
    setAudioSelected,
    activeScene,
    pageTransition,
    pageVariants,
    deviceSelected
  }: any = useContext(Context);

  const Loader = () => {
    const { progress } = useProgress()
    if (progress !== 100) {
      setSceneLoaderProgress(progress)
    } else {
      setSceneIsLoaded(true)
    }

    return <Html center>{progress} %</Html>
  }

  const readyHandler = () => {
    setIsReady(true);
    setTimeout(() => {
      setCountDownIsInView(false);
    }, 5000);
  };

  const finishMeditationHandler = () => {
    ReactGA.event({
      category: 'User Experience',
      action: 'Scene',
      label: `${activeScene.title}`
    });

    ReactGA.event({
      category: 'User Experience',
      action: 'Audio',
      label: `${audioSelected.id}`
    });

    ReactGA.event({
      category: 'User Experience',
      action: 'Device',
      label: `${deviceSelected}`
    });

    setTimeout(() => {
      navigate(`${ROUTES.ALL_DONE}`);
    }, 5000);
  }

  useEffect(() => {
    if (window.location.search.length) {
      const query = new URLSearchParams(window.location.search);
      const sceneParam = query.get('scene');
      if (sceneParam && sceneParam.toUpperCase() !== activeScene?.title.toUpperCase()) {
        const sceneObj = scenesList.find((el: any) => el.title.toUpperCase() === sceneParam.toUpperCase());
        if (sceneObj) {
          setActiveScene(sceneObj);
        } else {
          setActiveScene(scenesList[0]);
        }
      }

      const audioParam = query.get('audio');
      if (audioParam && audioParam.toUpperCase() !== audioSelected?.id?.toUpperCase()) {
        const audioObj = audios.find((el: any) => el.id.toUpperCase() === audioParam.toUpperCase());
        if (audioObj) {
          setAudioSelected(audioObj);
        } else {
          setAudioSelected(audios[0]);
        }
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    const trackTime = () => {
      const timeOnPage = Math.round((Date.now() - startTime) / 1000);
      ReactGA.event({
        category: 'Time on Page',
        action: 'Page Loaded',
        value: timeOnPage
      });
    };

    const interval = setInterval(trackTime, 10000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <motion.div
      className={CN({ 'countdown-page': countDownIsInView })}
      initial="in"
      animate="out"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
    >
      {countDownIsInView ? (
        <div className="tablet-timer-container">
          <Header linkAddress={`${ROUTES.MEDITATION_AUDIO}?&device=${deviceSelected}&scene=${activeScene?.title}&audio=${audioSelected.id}`} linkText="Back" logoColor="white"/>
          {isReady && <Countdown isReady={isReady}/>}
          <Canvas
            gl={{
              outputEncoding: THREE.sRGBEncoding,
              toneMapping: THREE.NoToneMapping,
              sortObjects: false,
              alpha: true,
              antialias: true,
            }}
          >
            <ambientLight intensity={1} />
            <pointLight position={[10, 10, 10]} />
            <Tablet isReady={isReady} />
          </Canvas>
          <div className="button-ready-container">
            <ButtonAction
              text={sceneIsLoaded
                ? "I'm ready"
                : `Scene is loaded ${Math.floor(sceneLoaderProgress)}%...`
              }
              filled
              onClickHandler={() => readyHandler()}
              hidden={isReady}
              disabled={!sceneIsLoaded}
            />
          </div>
        </div>
      ) : null}

        <div className="scene-container" style={ !countDownIsInView ? {} : {visibility: 'hidden'}}>
          <Canvas
            gl={{
              outputEncoding: THREE.sRGBEncoding,
              toneMapping: THREE.NoToneMapping,
              sortObjects: true,
              alpha: true,
              antialias: true,
            }}
          >
            {/*<<<< DEBUG COMPONENTS FOR DEVELOPERS >>>>*/}
            {/*@ts-ignore*/}
            {/*<axesHelper args={[3, 3, 3]} size={10} />*/}
            <ambientLight />
            <pointLight position={[0, 18, 40]} />
            {/*<<<< DEBUG COMPONENTS FOR DEVELOPERS >>>>*/}

          <Scene loading={countDownIsInView}/>

          {!sceneIsLoaded && <Loader/>}
        </Canvas>

        <Leva />

        <SoundControls
          isReady={isReady}
          finishMeditationHandler={finishMeditationHandler}
          activeScene={activeScene}
          audioSelected={audioSelected}
          device="tablet"
        />

      </div>
    </motion.div>
  );
}

export default ViewerPage;

import "./SoundControls.css"
import SoundProgress from '../SoundProgress/SoundProgress';
import React, {FC, useEffect, useRef, useState} from 'react';
import CN from 'classnames';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../app.router/ROUTES';
import Quote from '../Quote/Quote';

interface ISoundControls {
  isReady: boolean;
  finishMeditationHandler: () => void;
  audioSelected?: any;
  activeScene?: any;
  device?: string
}

const SoundControls: FC<ISoundControls> = ({isReady,
                                             finishMeditationHandler,
                                             audioSelected,
                                             activeScene,
                                             device
                                          }) => {

  const [marginLeft, setMarginLeft] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volumeIsOn, setVolumeIsOn] = useState(true);
  const [soundDuration, setSoundDuration] = useState(0);
  const [hintsAreVisible, setHintsAreVisible] = useState(false);
  const [playbackPercentage, setPlaybackPercentage] = useState(0);
  const [volumeThumbPosition, setVolumeThumbPosition] = useState(0);
  const [meditationIsFinished, setMeditationIsFinished] = useState(false);
  const [soundControlsVisible, setSoundControlsVisible] = useState(false);
  const [volumeSoundMeditation, setVolumeSoundMeditation] = useState(0.5);
  const [volumeSoundBackground, setVolumeSoundBackground] = useState(0.5);
  const [volumeSliderIsVisible, setVolumeSliderIsVisible] = useState(false);
  const [volumePercentage, setVolumePercentage] = useState(volumeSoundMeditation * 100);

  const audioMeditationRef = useRef<HTMLAudioElement>(null);
  const audioBackgroundRef = useRef<HTMLAudioElement>(null);

  const audioMeditation = audioMeditationRef.current;
  const audioBackground = audioBackgroundRef.current;

  const thumbWidth = 24;
  const centerThumb = (thumbWidth / 100) * volumePercentage * -1;

  const play = () => {
    if (audioMeditation && audioBackground) {

      setIsPlaying(!isPlaying)

      !isPlaying
        ? audioMeditation.play()
        : audioMeditation.pause();

      !isPlaying
        ? audioBackground.play()
        : audioBackground.pause();

      if (!soundControlsVisible) {
        setSoundControlsVisible(true)
      }
    }
  }

  const getCurrentDuration = (e: any) => {
    const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100)
    const time = e.currentTarget.currentTime

    setPlaybackPercentage(+percent)
    setCurrentTime(time.toFixed(2))
  }

  const volumeSliderHandler = () => {
    if(hintsAreVisible) {
      setHintsAreVisible(false)
    }
    setVolumeSliderIsVisible(!volumeSliderIsVisible)
  }

  const onVolumeChange = (e: any) => {
    setVolumePercentage(e.target.value)

    if (audioMeditation && audioBackground) {
      setVolumeSoundMeditation(Math.floor(volumePercentage) / 100)
      audioMeditation.volume = e.target.value / 100;
      audioBackground.volume = (e.target.value / 100) * volumeSoundBackground;
    }
  }

  const toggleVolumeHandler = () => {
    if (audioMeditation && audioBackground) {
      if (audioMeditation.volume !== 0) {
        audioMeditation.volume = 0;
        audioBackground.volume = 0;
        setVolumeThumbPosition(0);
        setMarginLeft(0)
      } else {
        audioMeditation.volume = volumeSoundMeditation;
        audioBackground.volume = volumeSoundMeditation * volumeSoundBackground;
        setVolumeThumbPosition(volumePercentage)
        setMarginLeft(centerThumb)
      }
    }
    setVolumeIsOn(!volumeIsOn);
  }

  const controlsVisibilityToggler = () => {
    setSoundControlsVisible(!soundControlsVisible)
  }

  const hideHintsHandler = () => {
    setHintsAreVisible(true)
    setTimeout(() => setHintsAreVisible(false),10000)
  }

  useEffect(() => {
    setTimeout(() => {
      setVolumeSoundBackground(activeScene.volume)
      if (audioBackground) {
        audioBackground.volume = volumeSoundBackground * volumeSoundBackground
      }
      audioBackground?.play();
    }, 4500)

    setTimeout(() => {
      play()
    }, 5000)

    setTimeout(hideHintsHandler, 5000);

  }, [isReady]);


  useEffect(() => {
    setMarginLeft(centerThumb)
    setVolumeThumbPosition(volumePercentage)
  }, [volumePercentage])

  useEffect(() => {
    if (currentTime === soundDuration && soundDuration !== 0) {
      audioBackground?.pause();
      audioMeditation?.pause();

      finishMeditationHandler();

      setMeditationIsFinished(true)
    }
  }, [currentTime, soundDuration]);

  return (
    <>
      <div>
        <SoundProgress
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          audioRef={audioMeditationRef}
          percentage={playbackPercentage}
          setPercentage={setPlaybackPercentage}
          currentTime={currentTime}
          duration={soundDuration}
          soundControlsVisible={soundControlsVisible}
        />
        <audio
          ref={audioMeditationRef}
          src={audioSelected && audioSelected.audioUrl}
          onLoadedData={(e: any) => {
            setSoundDuration(e.currentTarget.duration.toFixed(2));
          }}
          onTimeUpdate={getCurrentDuration}
        />
        <audio
          ref={audioBackgroundRef}
          src={activeScene && activeScene.backgroundAudioUrl}
          loop
        />
      </div>

      <div className={CN(
        {"sound-controls-hidden": !soundControlsVisible}
      )}>
        <div className="controls-container-link">
          {device === 'tablet' &&
            <Link to={`${ROUTES.DEVICES}?&scene=${activeScene?.title}&audio=${audioSelected.id}`}>
              <img src={'/assets/misc/arrow-left.svg'} alt="arrow left" className="controls-container-link-icon"/>
              <span className="controls-container-link-text">&nbsp;Back</span>
            </Link>
          }
          {device === 'vr' &&
            <a href={`${ROUTES.MEDITATION_AUDIO}?&scene=${activeScene?.title}&audio=${audioSelected.id}`}>
              <img src={'/assets/misc/arrow-left.svg'} alt="arrow left" className="controls-container-link-icon"/>
              <span className="controls-container-link-text">&nbsp;Back</span>
            </a>
          }
        </div>
      </div>

      <div className={CN(
        {"sound-controls-hidden": !soundControlsVisible}
      )}>
        <div className="sound-controls-container">
          {isPlaying ? <img
              src={'/assets/sound-controls/pause-button.svg'}
              onClick={play}
              alt="pause"
            />
            : <img
              src={'/assets/sound-controls/play-button.svg'}
              onClick={play}
              alt="play"
            />}
          <img
            src={volumeThumbPosition > 0
              ? ('/assets/sound-controls/volume-max-white.svg')
              : ('/assets/sound-controls/volume-off-white.svg')
            }
            alt="volume"
            onClick={volumeSliderHandler}
            className="volume-control-container-opener-button"
          />
        </div>
        {volumeSliderIsVisible && (<div className="volume-control-container">
          <img src={volumeThumbPosition > 0
            ? ('/assets/sound-controls/volume-off-orange.svg')
            : ('/assets/sound-controls/volume-max-orange.svg')
          }
               className="volume-icon-main"
               alt="volume"
               onClick={toggleVolumeHandler}
          />
          <div className="volume-slider-container">
            <div
              className="volume-slider-progress-bar-cover"
              style={{
                width: `${volumeThumbPosition}%`
              }}
            />
            <div
              className="volume-slider-thumb"
              style={{
                left: `${volumeThumbPosition}%`,
                marginLeft: `${marginLeft}px`
              }}
            />
            <input
              type="range"
              step="0.01"
              value={volumeThumbPosition}
              className="volume-slider"
              onChange={onVolumeChange}
            />
          </div>
        </div>)}
      </div>
      <div className={CN(
        {"sound-controls-hidden": !soundControlsVisible}
      )}>
        <div className={CN('hint-bubble-left hint-bubble-play-stop',
            {"hint-hidden": !hintsAreVisible}
          )}>
          Press to stop and continue meditation
        </div>
        <div className={CN('hint-bubble-left hint-bubble-volume',
          {"hint-hidden": !hintsAreVisible}
        )}>
          Adjust the audio track volume
        </div>
        <div className={CN('hint-bubble-interface-hide-show',
          {"hint-hidden": !hintsAreVisible}
        )}>
          Press to hide/show the interface
        </div>
      </div>

      <div
        onClick={controlsVisibilityToggler}
        className={CN(
          {"controls-showed": soundControlsVisible},
          {"controls-hidden": !soundControlsVisible}
        )}
      >
      </div>

      {meditationIsFinished && <Quote device={device || ""}/>}
    </>
  );
}

export default SoundControls;

import React, {useEffect, useState} from 'react';
import './App.css';
import AnimatedRoutes from './app.router/AnimatedRoutes';
import { Context } from './Context';
import { scenes } from './constants/app.config';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';

ReactGA.initialize('G-RCSPBZ6J1P');

const App = () => {
  const [disclaimerOpened, setDisclaimerOpened] = useState<boolean>(true);
  const [feelingValue, setFeelingValue] = useState<string>('2');
  const [sceneSelected, setSceneSelected] = useState<string>('');
  const [audioSelected, setAudioSelected] = useState<string>('');
  const [deviceSelected, setDeviceSelected] = useState<string>('');
  const [scenesList, setScenesLst] = useState<any>(scenes);
  const [activeScene, setActiveScene] = useState<any>(null);
  const [previousPage, setPreviousPage] = useState('');

  const pageVariants = {
    in: {
      opacity: 0.5,
      x: '+100vw',
    },
    out: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0.5,
      x: '-100vw',
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.5,
  };

  const memoizedContext = React.useMemo(() => ({
    disclaimerOpened,
    setDisclaimerOpened,
    feelingValue,
    setFeelingValue,
    sceneSelected,
    setSceneSelected,
    audioSelected,
    setAudioSelected,
    deviceSelected,
    setDeviceSelected,
    pageVariants,
    pageTransition,
    scenesList,
    setScenesLst,
    activeScene,
    setActiveScene,
    previousPage,
    setPreviousPage,
  }), [
    disclaimerOpened,
    setDisclaimerOpened,
    feelingValue,
    setFeelingValue,
    sceneSelected,
    setSceneSelected,
    audioSelected,
    setAudioSelected,
    deviceSelected,
    pageVariants,
    pageTransition,
    scenesList,
    setScenesLst,
    activeScene,
    setActiveScene,
    previousPage,
    setPreviousPage,
  ]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <div className="App">
      <Context.Provider value={memoizedContext}>
        <Toaster />
        <AnimatedRoutes/>
      </Context.Provider>
    </div>
  );
}

export default App;

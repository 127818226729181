import React, { useContext } from 'react';
import './AllDonePage.css';
import { motion } from 'framer-motion';
import { Context } from '../../Context';
import Header from '../../components/Header/Header';
import FeelingMeter from '../../components/FeelingMeter/FeelingMeter';
import FaqButton from '../../components/FaqButton/FaqButton';
import ScreenTitle from '../../components/ScreenTitle/ScreenTitle';
import ButtonLink from '../../components/Button.Library/Button.Link/ButtonLink';
import { ROUTES } from '../../app.router/ROUTES';
import ReactGA from 'react-ga';

const AllDonePage = () => {
  const {
    feelingValue,
    setFeelingValue,
    pageVariants,
    pageTransition,
    setScenery,
    setMeditation,
    setDevice,
  }: any = useContext(Context);

  const initialFeelingValue = +feelingValue;

  const letsGoHandler = () => {
    ReactGA.event({
      category: 'FeelingMeter',
      action: 'MoodImprovements',
      label: feelingValue
    });

    setScenery(null);
    setMeditation(null);
    setDevice(null);
  };

  return (
    <>
      <motion.div
        className="all-done-page"
        initial="in"
        animate="out"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Header linkAddress="/" linkText="Return to start" logoColor="blue" />
        <div className="all-done-page-title-container">
          {/*<ScreenTitle title="All done?" marginBottomPro116 />*/}
          <h2 className="all-done-page-title">
            Tell us about your experience
          </h2>
        </div>
        <div className="all-done-page-feeling-meter-container">
          <FeelingMeter
            feelingValue={feelingValue}
            setFeelingValue={setFeelingValue}
          />
        </div>
        <h2 className="all-done-page-subtitle-2">
          Would you like to experience another micro-vacation?
        </h2>
        <div className="all-done-page-buttons-container">
          <ButtonLink text="No, thanks" outlined linkAddress={`${ROUTES.THANK_YOU}`} />
          <ButtonLink
            text="Yes, let's go!"
            filled
            onClickHandler={() => letsGoHandler()}
            linkAddress={`${ROUTES.SCENERY}`}
          />
        </div>
      </motion.div>
      <FaqButton color="grey" />
    </>
  );
}

export default AllDonePage;

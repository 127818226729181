import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './ThankYouPage.css';
import Button from '../../components/Button.Library/Button.Link/ButtonLink';
import { Context } from '../../Context';
import { ROUTES } from '../../app.router/ROUTES';


const ThankYouPage = () => {
  const { pageVariants, pageTransition }: any = useContext(Context);

  return (
    <motion.div
      className="thank-you-page"
      initial="in"
      animate="out"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="thank-you-page-box">
        <img
          src={process.env.PUBLIC_URL + '/assets/logos/logo-white.svg'}
          alt="Cabana logo"
          className="thank-you-page-logo"
        />
        <h1 className="thank-you-page-title">
          Thank you for
          <br />
          choosing Cabana
        </h1>
        <div className="thank-you-page-button-container">
          <Button text="Back to home" filled linkAddress={`${ROUTES.HOME}`} />
        </div>
        <Link to={`${ROUTES.FINAL}`}>
          <h3 className="thank-you-page-subtitle">Create an account</h3>
        </Link>
      </div>
    </motion.div>
  );
}

export default ThankYouPage;

export const ROUTES = {
  HOME: '/',
  DISCLAIMER: '/disclaimer',
  SCENERY: '/scenery',
  MEDITATION_AUDIO: '/meditationselector',
  DEVICES: '/deviceselection',
  VIEWER: '/viewer',
  VR_GET_READY: '/vrgetready',
  VR_VIEWER: '/vrviewer',
  FAQ: '/faq',
  MEDITATION_START: '/meditationstart',
  POST_VR: '/postvr',
  ALL_DONE: '/alldone',
  THANK_YOU: '/thankyou',
  FINAL: '/account-creation',
}

import React, { FC, useRef } from 'react';
import { Stats, useGLTF } from '@react-three/drei';
import { tablet } from '../../constants/app.config';
import { useFrame } from '@react-three/fiber';

interface ITablet {
  isReady: boolean;
}

export const Tablet: FC<ITablet> = ({ isReady }) => {
  const { nodes, materials } = useGLTF(tablet.model);
  const myMesh: any = useRef();

  useFrame(({ clock }) => {
    myMesh.current.rotation.y = clock.getElapsedTime();

    if (isReady) {
      myMesh.current.position.z -= 0.009;
      myMesh.current.position.y += 0.0009;
    }
  });

  return (
    <group ref={myMesh} scale={0.7}>
      <group rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]}>
        <group rotation={[0, 0, 0]} scale={0.01}>
          <group scale={100} rotation-x={0.15}>
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.CameraModule_CamFrame_0.geometry}
              material={materials.CamFrame}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.CameraModule_CamFrame2_0.geometry}
              material={materials.CamFrame2}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.CameraModule_CamBackground_0.geometry}
              material={materials.CamBackground}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.CameraModule_CamGlass_0.geometry}
              material={materials.CamGlass}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Camera_LensFrame_0.geometry}
              material={materials.LensFrame}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Camera_LensBlack_0.geometry}
              material={materials.LensBlack}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Camera_LensGray_0.geometry}
              material={materials.LensGray}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.MicFlash_MicFlashBody_0.geometry}
              material={materials.MicFlashBody}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.MicFlash_Mic_0.geometry}
              material={materials.material}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.MicFlash_Flash_0.geometry}
              material={materials.Flash}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Lens1_Lens_0.geometry}
              material={materials.Lens}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Lens2_Lens_0.geometry}
              material={materials.Lens}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.PenCharge_Stripe_0.geometry}
              material={materials.Stripe}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Screen_Wallpaper_0.geometry}
              material={materials.Wallpaper}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Lens3_Lens_0.geometry}
              material={materials.Lens}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Lens4_Lens_0.geometry}
              material={materials.Lens}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Button_Body_0.geometry}
              material={materials.Body}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.akg_Text_0.geometry}
              material={materials.Text}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_Body_0.geometry}
              material={materials.Body}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_Bezel_0.geometry}
              material={materials.Bezel}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_Body2_0.geometry}
              material={materials.Body2}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_LensGray_0.geometry}
              material={materials.LensGray}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_Antenna_0.geometry}
              material={materials.Antenna}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_Body3_0.geometry}
              material={materials.Body3}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_CamGlass_0.geometry}
              material={materials.CamGlass}
            />
            <mesh
              castShadow={false}
              receiveShadow={false}
              geometry={nodes.Body_Mic_0.geometry}
              material={materials.material}
            />
          </group>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(tablet.model);

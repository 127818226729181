import React, {FC, useEffect} from 'react';
import CN from 'classnames';

import './FeelingMeter.css';

interface IFeelingMeterProps {
  feelingValue: string;
  setFeelingValue: (value: string) => void;
}

const FeelingMeter: FC<IFeelingMeterProps> = ({
                                                feelingValue,
                                                setFeelingValue
                                              }) => {

  const sliderHandler = (e: any) => {
    setFeelingValue(e.target.value);
  };

  const numberOnClickHandler = (value: string) => {
    const slider = document.querySelector('input');
    if (slider) {
      slider.style.setProperty('--value', value);
      slider.value = value;
      setFeelingValue(value);
    }
  }

  useEffect(() => {
    const feelingMeterInputRange = document.querySelectorAll(
      'input[type="range"].slider-progress',
    )

    if (feelingMeterInputRange) {
      feelingMeterInputRange.forEach((e: any) => {
        e.style.setProperty('--value', e.value || feelingValue);
        e.style.setProperty('--min', e.min === '' ? '0' : e.min);
        e.style.setProperty('--max', e.max === '' ? '4' : e.max);
        e.addEventListener('input', () => e.style.setProperty('--value', e.value));
      })
    }
  }, [feelingValue]);

  return (
    <div className="feeling-meter-container">
      <h2 className="feeling-meter-title">How are you feeling right now?</h2>
      <div className="feeling-meter-emojis-container">
        <img
          src={'/assets/mood-emojies/mood-1.svg'}
          alt="Bad mood emojie"
          className={CN('feeling-meter-emoji-hidden', {
            'feeling-meter-emoji-visible': feelingValue === '0',
          })}
        />
        <img
          src={'/assets/mood-emojies/mood-2.svg'}
          alt="Now so bad mood emojie"
          className={CN('feeling-meter-emoji-hidden', {
            'feeling-meter-emoji-visible': feelingValue === '1',
          })}
        />
        <img
          src={'/assets/mood-emojies/mood-3.svg'}
          alt="Meh mood emojie"
          className={CN('feeling-meter-emoji-hidden', {
            'feeling-meter-emoji-visible': feelingValue === '2',
          })}
        />
        <img
          src={'/assets/mood-emojies/mood-4.svg'}
          alt="Good mood emojie"
          className={CN('feeling-meter-emoji-hidden', {
            'feeling-meter-emoji-visible': feelingValue === '3',
          })}
        />
        <img
          src={'/assets/mood-emojies/mood-5.svg'}
          alt="Fantastic mood emojie"
          className={CN('feeling-meter-emoji-hidden', {
            'feeling-meter-emoji-visible': feelingValue === '4',
          })}
        />
      </div>
      <div className="feeling-meter-range-container">
        <input
          type="range"
          min={0}
          max={4}
          value={feelingValue}
          onChange={sliderHandler}
          className="styled-slider slider-progress"
          step={1}
        />
      </div>

      <div className="feeling-meter-numbers-container">
        <h2
          className={CN(
            'feeling-meter-number-not-active feeling-meter-number-1',
            { 'feeling-meter-number-active-1': feelingValue === '0' },
          )}
          onClick={() => numberOnClickHandler('0')}
        >
          1
        </h2>
        <h2
          className={CN(
            'feeling-meter-number-not-active feeling-meter-number-2',
            { 'feeling-meter-number-active-2': feelingValue === '1' },
          )}
          onClick={() => numberOnClickHandler('1')}
        >
          2
        </h2>
        <h2
          className={CN(
            'feeling-meter-number-not-active feeling-meter-number-3',
            { 'feeling-meter-number-active-3': feelingValue === '2' },
          )}
          onClick={() => numberOnClickHandler('2')}
        >
          3
        </h2>
        <h2
          className={CN(
            'feeling-meter-number-not-active feeling-meter-number-4',
            { 'feeling-meter-number-active-4': feelingValue === '3' },
          )}
          onClick={() => numberOnClickHandler('3')}
        >
          4
        </h2>
        <h2
          className={CN(
            'feeling-meter-number-not-active feeling-meter-number-5',
            { 'feeling-meter-number-active-5': feelingValue === '4' },
          )}
          onClick={() => numberOnClickHandler('4')}
        >
          5
        </h2>
      </div>
      <div className="feeling-meter-states">
        <h4
          className="feeling-meter-states-state feeling-meter-states-state-very-low"
          onClick={() => numberOnClickHandler('0')}
        >
          Very low
        </h4>
        <h4 className="feeling-meter-states-state feeling-meter-states-state-meh"
            onClick={() => numberOnClickHandler('2')}
        >
          Meh
        </h4>
        <h4 className="feeling-meter-states-state feeling-meter-states-state-fantastic"
            onClick={() => numberOnClickHandler('4')}
        >
          Fantastic
        </h4>
      </div>
    </div>
  );
}

export default FeelingMeter;

import * as THREE from "three";
import React, {FC, useRef} from 'react';
import { GLTF } from "three-stdlib";
import {useFrame} from '@react-three/fiber';
import {glasses} from '../../constants/app.config';
import {useGLTF} from 'uni-fiber';
import { Stats } from '@react-three/drei';

type GLTFResult = GLTF & {
  nodes: {
    Mesh: THREE.Mesh;
    Mesh_1: THREE.Mesh;
  };
  materials: {
    M_viveFlow_01: THREE.MeshStandardMaterial;
    M_viveFlow_02: THREE.MeshPhysicalMaterial;
  };
};

interface IGlasses {
  isReady: boolean
}

export const Glasses: FC<IGlasses> = ({isReady}) => {
  const { nodes, materials } = useGLTF(glasses.model) as GLTFResult;
  const myMesh: any = useRef()

  useFrame(({ clock }) => {
    myMesh.current.rotation.y = clock.getElapsedTime()

    if (isReady) {
      myMesh.current.position.z -= 0.01
    }
  })

  return (
    <group dispose={null} ref={myMesh} rotation-z={-0.45} scale={0.040}>
      <mesh
        castShadow={true}
        receiveShadow={true}
        geometry={nodes.Mesh.geometry}
        material={materials.M_viveFlow_01}
      >
      </mesh>
      <mesh
        castShadow={true}
        receiveShadow={true}
        geometry={nodes.Mesh_1.geometry}
        material={materials.M_viveFlow_02}
      />
    </group>
  );
}

useGLTF.preload(glasses.model);

import * as React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';
import StartPage from '../pages/StartPage/StartPage';
import DisclaimerPage from '../pages/DisclaimerPage/DisclaimerPage';
import ScenerySelectionPage from '../pages/ScenerySelectionPage/ScenerySelectionPage';
import MeditationSelectionPage from '../pages/MeditationSelectionPage/MeditationSelectionPage';
import DeviceSelectionPage from '../pages/DeviceSelectionPage/DeviceSelectionPage';
import ViewerPage from '../pages/ViewerPage/ViewerPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AllDonePage from '../pages/AllDonePage/AllDonePage';
import ThankYouPage from '../pages/ThankYouPage/ThankYouPage';
import FinalPage from '../pages/FinalPage/FinalPage';
import { ROUTES } from './ROUTES';
import VrViewerPage from '../pages/VrViewerPage/VrViewerPage';
import PostVrPage from '../pages/PostVrPage/PostVrPage';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          path={ROUTES.HOME}
          element={<StartPage />}
        />
        <Route
          path={ROUTES.DISCLAIMER}
          element={<DisclaimerPage />}
        />
        <Route
          path={ROUTES.SCENERY}
          element={<ScenerySelectionPage />}
        />
        <Route
          path={ROUTES.MEDITATION_AUDIO}
          element={<MeditationSelectionPage />}
        />
        <Route
          path={ROUTES.DEVICES}
          element={<DeviceSelectionPage />}
        />
        <Route
          path={ROUTES.VIEWER}
          element={<ViewerPage />}
        />
        <Route
          path={ROUTES.VR_VIEWER}
          element={<VrViewerPage />}
        />
        <Route
          path={ROUTES.FAQ}
          element={<FaqPage />}
        />
        <Route
          path={ROUTES.POST_VR}
          element={<PostVrPage />}
        />
        <Route
          path={ROUTES.ALL_DONE}
          element={<AllDonePage />}
        />
        <Route
          path={ROUTES.THANK_YOU}
          element={<ThankYouPage />}
        />
        <Route
          path={ROUTES.FINAL}
          element={<FinalPage />}
        />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;

import React, {useContext, useEffect} from 'react';
import CN from 'classnames';
import { motion } from 'framer-motion';
import { Context } from '../../Context';
import './DeviceSelectionPage.css';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Header from '../../components/Header/Header';
import FaqButton from '../../components/FaqButton/FaqButton';
import { ROUTES } from '../../app.router/ROUTES';
import {useNavigate} from 'react-router-dom';
import { audios } from '../../constants/app.config';
import ButtonLink from '../../components/Button.Library/Button.Link/ButtonLink';
import toast from 'react-hot-toast';

const DeviceSelectionPage = () => {
  const {
    scenesList,
    activeScene,
    setActiveScene,
    audioSelected,
    setAudioSelected,
    deviceSelected,
    setDeviceSelected,
    pageTransition,
    pageVariants,
    setPreviousPage
  }: any = useContext(Context);

  const navigate = useNavigate();

  const vrToast = () => {
    toast.success('VR coming soon')
  }

  useEffect(() => {
    if (window.location.search.length) {
      const query = new URLSearchParams(window.location.search);
      const sceneParam = query.get('scene');
      if (sceneParam && sceneParam.toUpperCase() !== activeScene?.title.toUpperCase()) {
        const sceneObj = scenesList.find((el: any) => el.title.toUpperCase() === sceneParam.toUpperCase());
        if (sceneObj) {
          setActiveScene(sceneObj);
        } else {
          setActiveScene(scenesList[0]);
        }
      }

      const audioParam = query.get('audio');
      if (audioParam && audioParam.toUpperCase() !== audioSelected?.id?.toUpperCase()) {
        const audioObj = audios.find((el: any) => el.id.toUpperCase() === audioParam.toUpperCase());
        if (audioObj) {
          setAudioSelected(audioObj);
        } else {
          setAudioSelected(audios[0]);
        }
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    setPreviousPage(`${ROUTES.DEVICES}`);
  }, []);

  return (
    <>
      <motion.div
        className="device-page"
        initial="in"
        animate="out"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Header linkAddress={`${ROUTES.DISCLAIMER}?&scene=${activeScene?.title}&audio=${audioSelected.id}`} linkText="Back" logoColor="blue" />
        <div className="device-page-container">
          <ProgressBar
            image={<img src={'/assets/progress-bars/progress_line_step_2.svg'} alt="progress bar" className="progressbar-image" />}
          />
          <h2 className="device-page-title">
            Confirm device selection
          </h2>
          <div className="device-page-devices-container">
            <div className="device-page-device-image-container">
              <img
                src={'/assets/device-backgrounds/vr-device.png'}
                alt='VR'
                className={CN(
                  'device-page-device-image device-page-device-image-unselected'
                )}

                onClick={vrToast}
              />
              <p className="device-page-device-text">
                VR<br/>
                - <br/>
                Coming soon
              </p>

            </div>
            <div className="device-page-device-image-container">
              <img
                src={'/assets/device-backgrounds/tablet-device.png'}
                alt="Tablet"
                className={CN(
                  'device-page-device-image',
                  {
                    'device-page-device-image-selected':
                      deviceSelected === 'tablet',
                  },
                  {
                    'device-page-device-image-unselected':
                      deviceSelected !=='' && deviceSelected !== 'tablet',
                  },
                )}
                onClick={() => setDeviceSelected('tablet')}
              />
              <p className="device-page-device-text">Tablet</p>
            </div>
          </div>
          <h3 className={CN('device-page-vr-subtitle', {'device-page-vr-subtitle-hidden': deviceSelected !== 'vr'})}>
            Next steps will be completed in VR
          </h3>
          <div className="device-page-buttons-container">
            <ButtonLink
              text="Next"
              filled
              linkAddress={`${ROUTES.SCENERY}?&device=${deviceSelected}`}
              disabled={!deviceSelected}
            />
          </div>
        </div>
      </motion.div>
      <FaqButton color="grey" />
    </>
  );
}

export default DeviceSelectionPage;

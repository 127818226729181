import React, {FC, useEffect, useState} from 'react';
import "./SoundProgress.css";
import CN from 'classnames';

interface ISoundProgress {
  isPlaying: boolean,
  setIsPlaying: (value: boolean) => void,
  audioRef: any,
  percentage: number,
  setPercentage: (value: number) => void,
  currentTime: number,
  duration: number,
  soundControlsVisible: boolean
}

const SoundProgress: FC<ISoundProgress> = ({
                                             audioRef,
                                             percentage,
                                             setPercentage,
                                             currentTime,
                                             duration,
                                             soundControlsVisible
                                           }) => {

  const [playbackPosition, setPlaybackPosition] = useState(0);



  const onPlaybackChange = (e: any) => {
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.currentTime = (audio.duration / 100) * e.target.value;
      setPercentage(e.target.value)
    }
  }

  useEffect(() => {
    setPlaybackPosition(percentage)
  }, [percentage]);


  return (
    <>
    <div className="sound-progress-container">
      <div className="sound-progress-title-container">
        <h3 className={CN('sound-progress-title',
            {"sound-controls-hidden": !soundControlsVisible}
          )}
        >
          Welcome!
        </h3>
        <h3 className='sound-progress-title'>
          {Math.floor(currentTime / 60)} min
          <span className="total-duration">&nbsp;/ {Math.round(duration/60)} min</span></h3>
      </div>
      <div className={CN('slider-container',
          {"sound-controls-hidden": !soundControlsVisible}
        )}
      >
        <div className="progress-bar-cover" style={{
          width: `${playbackPosition}%`,
        }}>
        </div>

        <input
          type="range"
          step="0.01"
          className="range"
          onChange={onPlaybackChange}
        />

      </div>
    </div>
    </>
  );
}

export default SoundProgress;

import {
  useGLTF,
  useAnimations,
  OrbitControls,
  useModelDecorator,
} from 'uni-fiber';
import * as THREE from 'three';
import { Context } from '../../Context';
import { Grass } from './components/Grass';
import { Ocean } from './components/water';
import { useFrame, useThree } from '@react-three/fiber';
import {Cloud, Sparkles, Stats} from '@react-three/drei';
import { BlobGeometry } from './components/BlobGeometry';
import { OrbitControls as OrbitControlsType } from 'three-stdlib';
import React, { useContext, useEffect, useRef, Suspense, useState, FC } from 'react';
import { Projection, ProjectionGroup } from '../../utils/THREE.Helpers/Projection';

interface IProps {
  loading: boolean;
}

export const Scene: FC<IProps> = ({ loading }) => {
    const {
      activeScene,
      deviceSelected
    }: any = useContext(Context);

    const controlsRef = useRef<OrbitControlsType>(null!);
    const [grassMesh, setGrassMesh] = useState<any>(null);
    const [controls, setControls] = useState<OrbitControlsType>(null!);
    const [synchronizeModel, setSynchronizeModel] = useState<boolean>(false);

    const { camera, scene } = useThree();

    const DisableRender = () => useFrame(() => null, 1000);

    const { scene: modelScene, animations } = useGLTF(activeScene.model);

    const anims = useAnimations(animations, modelScene);

    function randomIntFromInterval(max: number) { // min and max included
      return Math.floor(Math.random() * (max - 1 + 1) + 1) - 1;
    }

    const handlePlayAnimalsAction = (event: any = null) => {
      if (activeScene.animalsActions.length && !event) {
        playAnimalsActions(activeScene.animalsActions);
      } else if (activeScene.animalsActions.length && event) {
        event.action.getMixer().removeEventListener('loop', handlePlayAnimalsAction);
        playAnimalsActions(activeScene.animalsActions);
      }
    };

    const playAnimalsActions = (actions: {name: string, duration: number}[]) => {
      if (actions.length) {
        const index = randomIntFromInterval(actions.length);
        const selectedAnim = actions[index];
        const sceletedAction = anims.actions[selectedAnim.name];
        sceletedAction?.setDuration(selectedAnim.duration);
        sceletedAction?.play();
        sceletedAction?.getMixer().addEventListener('loop', handlePlayAnimalsAction);
      }
    };

    const initAnimation = (names: {name: string, duration: number}[]) => {
      names.forEach(el => {
        anims.actions[el.name]?.setDuration(el.duration);
        anims.actions[el.name]?.play();
      });
    };

    // Decorate custom objects on scene
    useModelDecorator(activeScene.customItems, modelScene);

    // Trigger animation on scene, environment actions and animals actions
    useEffect(() => {
      if (anims && anims.names.length && activeScene.animationNames.length) {
        initAnimation(activeScene.animationNames);
      }
      if (anims && anims.names.length && activeScene && activeScene.animalsActions && activeScene.animalsActions.length) {
        handlePlayAnimalsAction();
      }
    }, [anims, activeScene]);

    // Find mesh for adding grass
    useEffect(() => {
      if (modelScene && activeScene.frustumCulledObjectNames.length) {
        activeScene.frustumCulledObjectNames.forEach((name: string) => {
          const obj = modelScene.getObjectByName(name);
          obj.frustumCulled = false;
        });
      }
    }, [modelScene, activeScene]);

    // Find mesh for adding grass
    useEffect(() => {
      if (modelScene && synchronizeModel && activeScene.grassMesh) {
        const grass = modelScene.getObjectByName(activeScene.grassMesh);
        const copyMesh = grass?.clone();

        grass.visible = true;
        copyMesh.visible = false;
        scene.add(copyMesh);
        if (grass) {
          setGrassMesh(copyMesh);
        } else {
          setGrassMesh(null);
        }
      }
    }, [modelScene, synchronizeModel]);

    // Rotate model for sync model with pano
    useEffect(() => {
      if (modelScene && activeScene.modelRotation) {
        const box = modelScene.getObjectByName('Camera_Box');
        const water = modelScene.getObjectByName('SM_Water_01');
        const cameraHelper = modelScene.getObjectByName('CameraPlace->Here');

        modelScene.remove(box);
        modelScene.remove(water);
        modelScene.remove(cameraHelper);
        modelScene.rotation.set(...activeScene.modelRotation);
        setSynchronizeModel(true);
      } else {
        setSynchronizeModel(false);
      }
    }, [modelScene]);

    // Set controls
    useEffect(() => {
      setControls(controlsRef.current);
    }, [controlsRef.current]);

    // @ts-ignore
    return (
      <group>
        {/*TODO need add this stoping render */}
        {loading ? <DisableRender/> : null}

        <OrbitControls
          enableZoom={deviceSelected === 'vr' ? true : false}
          // This code is commented out for development mode >>>
          // maxDistance={0.001}
          // This code is commented out for development mode <<<
          rotateSpeed={-1}
          ref={controlsRef}
        />

        {
          controls ? (
            <ProjectionGroup
              wireframe={false}
              animation={{
                easing: 'outSine',
                speed: 1,
              }}
              controls={controls}
              environment={modelScene}
              objectsWithCustomMaterials={
                activeScene && activeScene.customItems &&
                activeScene.customItems.map((el: any) => el.mesh)
                  .flat(2)
              }
            >
              <Projection
                active={true}
                encoding={THREE.LinearEncoding}
                position={activeScene.position as THREE.Vector3Tuple}
                image={activeScene.pano}
                onUpdate={() => {
                  const positionT = new THREE.Vector3(...activeScene.position);

                  positionT.sub(new THREE.Vector3(0, 0, activeScene.camera_Z));

                  camera.position.copy(positionT);
                }}
              >
                <mesh
                  visible={false}
                >
                  <sphereGeometry args={[0.05, 16, 16]}/>
                  <meshBasicMaterial color={0xFF0000}/>
                </mesh>
              </Projection>
            </ProjectionGroup>
          ) : null}

        <Suspense fallback={null}>
          {
            activeScene.waterMesh && activeScene.waterConfig &&
            <Ocean configuration={{ ...activeScene.waterConfig }}/>
          }
          {
            grassMesh && activeScene.grassConfig && activeScene.grassMesh && deviceSelected === 'tablet' &&
            <Grass {...{ ...activeScene.grassConfig, rotate: [...activeScene.modelRotation] }}>
              <BlobGeometry
                // @ts-ignore
                mesh={grassMesh}
              />
            </Grass>
          }

          {
            activeScene.sparkles && activeScene.sparkles.length && deviceSelected === 'tablet' && activeScene.sparkles.map((el: any, index: number) =>
              <Sparkles
                key={index}
                {
                  ...{
                    ...el,
                    speed: el.speed,
                    opacity: el.opacity,
                  }
                }
              />,
            )}

          {
            activeScene.clouds && activeScene.clouds.length && activeScene.clouds.map((el: any, index: number) =>
              <Cloud key={index + el.color} {...el}/>)
          }
        </Suspense>
      </group>
    );
  }
;

import React, {createRef, useContext, useEffect, useState} from 'react';
import './VrViewerPage.css';
import { Context } from '../../Context';
import {Canvas} from '@react-three/fiber';
import * as THREE from 'three';
import { Scene } from '../../components/WebGL.components/Scene';
import {audios} from '../../constants/app.config';
import {VRButton, XR, Controllers, Hands} from '@react-three/xr';
import {Html, PerspectiveCamera, useProgress} from '@react-three/drei';
import Countdown from '../../components/Countdown/Countdown';
import { ROUTES } from '../../app.router/ROUTES';
import {useNavigate} from 'react-router-dom';
import CN from 'classnames';
import Header from '../../components/Header/Header';
import { Glasses } from '../../components/WebGL.components/Glasses';
import {motion} from 'framer-motion';
import SoundControls from '../../components/SoundControls/SoundControls';
import ButtonAction from '../../components/Button.Library/Button.Action/ButtonAction';

const VrViewerPage = () => {
  const {
    scenesList,
    setActiveScene,
    audioSelected,
    setAudioSelected,
    activeScene,
    pageVariants,
    pageTransition
  }: any = useContext(Context);

  const [userIsReady, setUserIsReady] = useState(false);
  const [blueOverlayIsVisible, setBlueOverlayIsVisible] = useState(true);
  const [sceneLoaderProgress, setSceneLoaderProgress] = useState(0);
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  const navigate = useNavigate();

  const buttonRef = createRef<HTMLButtonElement>();

  const readyHandler = () => {
    setUserIsReady(true);
  };

  const Loader = () => {
    const { progress } = useProgress()
    if (progress !== 100) {
      setSceneLoaderProgress(progress)
    } else {
      setSceneIsLoaded(true)
    }

    return <Html center>{progress} %</Html>
  }

  useEffect(() => {
    if (userIsReady) {
      setTimeout(() => {
        buttonRef.current?.click();
        setBlueOverlayIsVisible(false)
      }, 5000);
    }
  }, [userIsReady]);

  const finishMeditationHandler = () => {
    setTimeout(() => {
      navigate(`${ROUTES.ALL_DONE}`);
    }, 5000);

    setTimeout(() => {
      buttonRef.current?.click();
    }, 4995)
  }

  useEffect(() => {
    if (window.location.search.length) {
      const query = new URLSearchParams(window.location.search);
      const sceneParam = query.get('scene');
      if (sceneParam && sceneParam.toUpperCase() !== activeScene?.title.toUpperCase()) {
        const sceneObj = scenesList.find((el: any) => el.title.toUpperCase() === sceneParam.toUpperCase());
        if (sceneObj) {
          setActiveScene(sceneObj);
        } else {
          setActiveScene(scenesList[0]);
        }
      }

      const audioParam = query.get('audio');
      if (audioParam && audioParam.toUpperCase() !== audioSelected?.id?.toUpperCase()) {
        const audioObj = audios.find((el: any) => el.id.toUpperCase() === audioParam.toUpperCase());
        if (audioObj) {
          setAudioSelected(audioObj);
        } else {
          setAudioSelected(audios[0]);
        }
      }
    }
  }, []);

  return (
    <>
      <motion.div
        className={CN(
          "vr-viewer-page",
          {"hidden-element": !blueOverlayIsVisible}
        )}
        initial="in"
        animate="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <div className={CN({"hidden-element": !blueOverlayIsVisible})}>
          <Header linkAddress={`${ROUTES.DEVICES}?&scene=${activeScene?.title}&audio=${audioSelected.id}`} linkText="Back" logoColor="white" />
          {userIsReady && <Countdown isReady={userIsReady}/>}
          <h1 className={CN('vr-viewer-page-title')}>
            Get ready! Put on the VR headset
          </h1>
          <div className="button-ready-container">

            <ButtonAction
              text={sceneIsLoaded
                ? "I'm ready"
                : `Scene is loaded ${Math.floor(sceneLoaderProgress)}%...`
              }
              filled
              onClickHandler={() => readyHandler()}
              hidden={userIsReady}
              disabled={!sceneIsLoaded}
            />
          </div>

        </div>
        <div className={CN("vr-get-ready-page-model-glasses-box",
          {"hidden-element": !blueOverlayIsVisible})}
        >
          <Canvas
            gl={{
              outputEncoding: THREE.sRGBEncoding,
              toneMapping: THREE.NoToneMapping,
              sortObjects: false,
              alpha: true,
              antialias: true,
            }}
          >
            <ambientLight intensity={0.5}/>
            <pointLight position={[10, 10, 10]}/>

            <Glasses isReady={userIsReady}/>

          </Canvas>
        </div>
      </motion.div>
      <>
        <div className={CN('scene-container',
          {'scene-container-hidden': !userIsReady}
        )}
        >
          <VRButton
            className="vr-button-hidden"
            ref={buttonRef}
          />
          <Canvas
            gl={{
              outputEncoding: THREE.sRGBEncoding,
              toneMapping: THREE.NoToneMapping,
              sortObjects: true,
              alpha: true,
              antialias: true,
            }}
          >
            <XR>
              <Controllers/>
              <Hands/>
              <ambientLight />

              <Scene loading={blueOverlayIsVisible}/>

              {!sceneIsLoaded && <Loader/>}

              <PerspectiveCamera
                makeDefault
              >
                <mesh
                  position={[-3.7, 2.3, -5]}
                >
                  <Html className={CN('sound-controls-vr-container',
                    {'sound-controls-vr-container-hidden': blueOverlayIsVisible}
                  )}
                  >
                    <SoundControls
                      isReady={userIsReady}
                      finishMeditationHandler={finishMeditationHandler}
                      audioSelected={audioSelected}
                      activeScene={activeScene}
                      device="vr"
                    />
                  </Html>
                </mesh>
              </PerspectiveCamera>
            </XR>
          </Canvas>
        </div>
      </>
    </>
  );
};

export default VrViewerPage;
